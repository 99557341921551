import React from 'react';
import moment from "moment";
import useRequest from "../hooks/useRequest";
import "../table.css";

const styles = {
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    margin: 16,
    width: 'calc(100% - 32px)',
    position: 'relative',
    height: 400,
    overflow: 'scroll'
  },
  reload: {
    position: 'absolute',
    top: 12,
    right: 16,
  },
  loading: {
    textAlign: 'center',
  },
  error: {
    textAlign: 'center',
    color: 'red'
  }
}

// {"Language":"en_TR","Manufacturer":"Apple","Latitude":"","AppVersion":"9","SystemName":"iOS","SystemVersion":"14.6.0","Longitude":"","Model":"iPhone13,1","AppName":"DCodeDemo","AppId":"com.dcode.demo"}

const metricKeys = ["Language", "Manufacturer", "Latitude", "Longitude", "AppVersion", "SystemName", "SystemVersion", "Model", "AppName"];

if (!(process.env.REACT_APP_HIDE_APP_ID === "true")) {
  metricKeys.push("AppId");
}

const MetricList = () => {
  const [requestState, reload] = useRequest({
    url: 'metric',
    method: 'get',
  });
  const { loading, loaded, result, error } = requestState;
  const metricList = result || [];
  return (
    <>
      <div style={styles.container}>
        {loading ? <p style={styles.loading}>loading...</p> : (
          <p style={styles.error}>{error}{'.'}</p>
        )}
        <button type="button" style={styles.reload} onClick={reload}>Reload</button>
        {loaded ? (
          <table>
            <thead>
            <tr>
              <th>_id</th>
              <th>Path</th>
              <th>Created</th>
              <th>Updated</th>
              {metricKeys.map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
            </thead>
            <tbody>
            { metricList.length === 0 ? (
              <tr>
                <td colSpan={metricKeys.length + 4}>
                  No Metric Yet.
                </td>
              </tr>
            ) : null}
            {
              metricList.map(({ _id, path, data, createdAt, updatedAt }) => (
                <tr key={_id}>
                  <td>{_id}</td>
                  <td>{path}</td>
                  <td>{moment(createdAt).format('lll')}</td>
                  <td>{moment(updatedAt).format('lll')}</td>
                  {metricKeys.map((key) => (
                    <td>{(data || {})[key] || ''}</td>
                  ))}
                </tr>
              ))
            }
            </tbody>
          </table>
        ) : null}
      </div>
    </>
  )
}

export default MetricList;
