import React, {useCallback, useState} from 'react';
import useToken from "../hooks/useToken";

const styles = {
  form: {
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyAlign: 'center',
    alignItems: 'center',
    padding: 20,
    margin: 16,
    width: 'calc(100% - 32px)',
  },
  heading: {
    marginTop: 0,
  }
}

const TokenHandler = () => {
  const [token, setToken] = useToken();
  const [tmpToken, setTmpToken] = useState(token);
  const onTokenSubmit = useCallback((e) => {
    e.preventDefault();
    setToken(tmpToken);
  }, [setToken, tmpToken])

  const onTokenChange = useCallback((e) => {
    setTmpToken(e.target.value);
  }, [setTmpToken])

  return (
    <form style={styles.form} onSubmit={onTokenSubmit}>
      <h3 style={styles.heading}>Set Token</h3>
      <div>
        <input type="text" name="token" onChange={onTokenChange} value={tmpToken}></input>
        <input type="submit" value="Update Token"></input>
      </div>
    </form>
  )
}

export default TokenHandler;
