import React, {useCallback, useEffect, useState} from 'react';

const subscriptions = [];

const updateToken = (newToken) => {
  localStorage.setItem('secret-token', newToken);
  subscriptions.forEach(setToken => setToken(newToken));
};

const useToken = () => {
  const [token, setToken] = useState(localStorage.getItem('secret-token') || '');

  useEffect(() => {
    subscriptions.push(setToken);
    return () => {
      const index = subscriptions.findIndex((a) => a === setToken);
      if (index >= 0) {
        subscriptions.splice(index, 1);
      }
    }
  }, [setToken]);

  return [token, updateToken];
}

export default useToken;
