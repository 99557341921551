import TokenHandler from "./component/TokenHandler";
import useToken from "./hooks/useToken";
import CodeList from "./component/CodeList";
import MetricList from "./component/MetricList";

const styles = {
  body: {
  }
}

function App() {
  const [token] = useToken();
  return (
    <div style={styles.body}>
      <TokenHandler />
      { token ? <CodeList /> : null}
      { token ? <MetricList /> : null}
    </div>
  );
}

export default App;
