import React, {useCallback, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import useToken from "./useToken";

const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v2/`;
// const SERVER_URL = 'http://localhost:8080/api/v2/';

const FETCH_REQUEST = 'FETCH_REQUEST';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';

const initialState = {
  loading: false,
  loaded: false,
  error: '',
  result: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: state.loaded,
        error: '',
      };
    case FETCH_SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: '',
        result: action.data,
      };
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const _defaultResultMapper = (data) => data;

function useRequest({
  resultMapper = _defaultResultMapper,
  url,
  method,
  body,
  headers,
  extras,
  enabled = true,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [token] = useToken();
  const reloadRequest = useCallback(async (force) => {
    if (!url || !method || (!enabled && !force)) {
      return;
    }
    dispatch({ type: FETCH_REQUEST });
    let request = null;

    try {
      const finalHeaders = {
        Authorization: token ? `Token ${token}` : '',
        ...headers,
      };
      switch (method.toUpperCase()) {
        case 'GET':
        case 'HEAD':
          request = axios({
            url,
            method,
            headers: finalHeaders,
            params: body,
            baseURL: SERVER_URL,
            ...extras,
          });
          break;
        case 'POST':
        case 'PUT':
        case 'DELETE':
          request = axios({
            url,
            method,
            headers: finalHeaders,
            data: body,
            baseURL: SERVER_URL,
            ...extras,
          });
          break;
        default:
          throw new Error('Unknown method in request hook, ', method);
      }

      const response = await request;
      if (response.data.error) {
        throw response.data.error;
      }
      dispatch({
        type: FETCH_SUCCESS,
        data: resultMapper(response.data),
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ERROR, error: e.message });
      return false;
    }
  }, [resultMapper, url, method, enabled, body, headers, extras]);
  useEffect(() => {
    if (url && method && enabled) {
      reloadRequest().catch((e) => console.error(e));
    }
  }, [url, enabled, method, body, headers, extras, reloadRequest]);
  return [state, reloadRequest];
}

useRequest.methods = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

useRequest.createPropType = (resultPropType = PropTypes.any) =>
  PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    result: resultPropType,
  });

export default useRequest;
